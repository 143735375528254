<template>
  <div class="device-menu">
    <div class="main-menu">
      <router-link
        v-if="menuPermission.Device.TowerCrane"
        :to="{ name: 'TowerCrane' }"
        :class="[
          $route.name == 'TowerCrane' ? 'child-menu-active' : '',
        ]">塔机设备
      </router-link>
      <router-link
        v-if="menuPermission.Device.Elevator"
        :to="{ name: 'Elevator' }"
        :class="[
          $route.name == 'Elevator' ? 'child-menu-active' : '',
        ]">升降机设备
      </router-link>
      <router-link
        v-if="menuPermission.Device.DischargingPlatform"
        :to="{ name: 'DischargingPlatform' }"
        :class="[
          $route.name == 'DischargingPlatform' ? 'child-menu-active' : '',
        ]">卸料设备
      </router-link>
      <router-link
        v-if="menuPermission.Device.ElectricityMonitoring"
        :to="{ name: 'ElectricityMonitoring' }"
        :class="[
          $route.name == 'ElectricityMonitoring' ? 'child-menu-active' : '',
        ]">用电设备
      </router-link>
      <router-link
        v-if="menuPermission.Device.DustMonitoring"
        :to="{ name: 'DustMonitoring' }"
        :class="[
          $route.name == 'DustMonitoring' ? 'child-menu-active' : '',
        ]">扬尘设备
      </router-link>
      <!-- <router-link
        v-if="menuPermission.Device.IntelligenceEye"
        :to="{ name: 'IntelligenceEye' }"
        :class="[
          $route.name == 'IntelligenceEye' ? 'child-menu-active' : '',
        ]">智眸AI
      </router-link> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeviceMenu',
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    menuPermission: function () {
      return JSON.parse(localStorage.getItem("menuPermission"));
    },
  },
}
</script>

<style lang="less" scoped>
@link-color: #2c85d8;

.device-menu {
  width: 1200px;
  margin: 0 auto;
  a {
    display: inline-block;
    padding: 10px 20px;
  }
}
</style>
